import React from "react";
import ReactResizeDetector from "react-resize-detector";
import ModelViewerBase from "./modelviewerbase";

const styles = {
  root: {
    position: "relative"
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
};

function shouldMountModel(props, state) {
  if (["hover", "click"].includes(props.defer)) {
    if (state.touched) {
      return true;
    } else {
      return false;
    }
  }
  return true;
}

class ModelViewer extends React.Component {
  static loadingStates = {
    BEFORE: "BEFORE",
    LOADING: "LOADING",
    LOADED: "LOADED",
    ERROR: "ERROR"
  };

  constructor(props) {
    super(props);
    this.state = {
      loadingState: ["hover", "click"].includes(this.props.defer)
        ? ModelViewer.loadingStates.BEFORE
        : ModelViewer.loadingStates.LOADING,
      touched: false
    };
    this.modelViewerBase = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    let oldShouldMountModel = shouldMountModel(prevProps, prevState);
    let newShouldMountModel = shouldMountModel(this.props, this.state);
    if (newShouldMountModel === true) {
      if (
        prevProps.src !== this.props.src ||
        oldShouldMountModel !== newShouldMountModel
      ) {
        this.setState({ loadingState: ModelViewer.loadingStates.LOADING });
      }
    }
  }

  resetCamera = () => {
    this.modelViewerBase.current && this.modelViewerBase.current.resetCamera();
  };

  markTouched = () => !this.state.touched && this.setState({ touched: true });

  render() {
    let mountModel = shouldMountModel(this.props, this.state);

    const handleProgress = (...args) => {
      this.props.onProgress(...args);
    };
    const handleLoad = (...args) => {
      try {
        this.setState({ loadingState: ModelViewer.loadingStates.LOADED });
        this.props.onLoad(...args);
      } catch (e) {
        console.error(e);
      }
    };
    const handleError = (...args) => {
      this.setState({ loadingState: ModelViewer.loadingStates.ERROR });
      this.props.onError(...args);
    };

    return (
      <div
        style={{
          ...styles.root,
          height: this.props.height,
          width: this.props.width
        }}
        className={this.props.className}
        onClick={this.props.defer === "click" ? this.markTouched : null}
        onMouseOver={this.props.defer === "hover" ? this.markTouched : null}
      >
        <ReactResizeDetector handleWidth handleHeight>
          {({ width, height }) => (
            <div>
              {mountModel && (
                <ModelViewerBase
                  ref={this.modelViewerBase}
                  src={this.props.src}
                  width={width}
                  height={height}
                  onProgress={handleProgress}
                  onLoad={handleLoad}
                  onError={handleError}
                  canvasRef={this.props.canvasRef}
                  antialias={this.props.antialias}
                  children={this.props.children}
                  preventDefault={this.props.preventDefault}
                />
              )}
              {this.state.loadingState === ModelViewer.loadingStates.BEFORE && (
                <div style={styles.overlay}>
                  {this.props.renderBeforeLoading()}
                </div>
              )}
              {this.state.loadingState ===
                ModelViewer.loadingStates.LOADING && (
                <div style={styles.overlay}>{this.props.renderOnLoading()}</div>
              )}
              {this.state.loadingState === ModelViewer.loadingStates.ERROR && (
                <div style={styles.overlay}>{this.props.renderOnError()}</div>
              )}
            </div>
          )}
        </ReactResizeDetector>
      </div>
    );
  }
}

ModelViewer.defaultProps = {
  src: "",
  canvasRef: c => {},
  height: "100%",
  width: "100%",
  onLoad: () => {},
  onProgress: () => {},
  onError: () => {},
  antialias: true,
  renderBeforeLoading: () => {},
  renderOnLoading: () => {},
  renderOnError: () => {},
  preventDefault: true
};

export default ModelViewer;
